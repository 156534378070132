@import "bootstrap/bootstrap.less";
@import "fonts.less";
@import "variables.less";

/**
* Custom Elements
**/
a.lbtag-info-box {

  color:@colorWhite;
  text-decoration: none;
  float:right;

  div {
    color: @colorWhite;
    background-color: @colorRed;
    font-size: 26px;
    text-transform: uppercase;
    padding: 12px 12px;
    height: 176px;
    margin-bottom:15px;
  }

}

.margin-0 {
  margin-left:0px;
  margin-right:0px;
}

/**
* LESS
**/
html, body {
  width:100%;
  height:100%;
}

body {
  font-family:@standardFont;
  font-weight:300;
  background-image: url('../img/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center center;
  background-attachment: fixed;

  #wrap {
    min-height: 100%;
    #main {
      overflow:auto;
      padding-bottom:200px;
    }
  }

  .footer {
    position: relative;
    background-color:@colorBlack;
    border-top:1px solid @colorGrey;
    color:@colorWhite;
    margin-top: -120px; /* negative value of footer height */
    min-height: 120px;
    clear:both;
    padding:20px 0px;

    p {
      margin:0px;
    }

    p.footer-title {
      font-size:1.2em;
      line-height:2em;
      font-weight: 400;
    }

    p.footer-text {
      font-size:0.9em;
      line-height:1.8em;
    }

    img.footer-logo {
      display:block;
      margin-bottom:6px;
      width:140px;
    }

    a {
      color:@colorWhite;
      text-decoration:underline;
    }
  }

  @media (max-width: @screen-sm-max) {
    .footer {
      height:auto;
      .col {
        margin-bottom:20px;
      }
    }
  }

  /**
  * Allgemeine Formatierungen
  **/
  h1 {
    font-weight: 200;
    font-size:24pt;
    text-transform: uppercase;
    letter-spacing: 0.05em;

    margin:16px 0px 10px 0px;
  }

  h2 {
    font-weight: 300;
    //font-size: 26px;
    text-transform: uppercase;
    line-height:1.2em;
    margin:16px 0px 6px 0px;
  }

  h3 {
    //text-transform: uppercase;
    font-weight:300;
  }

  p {
    font-weight: 300;
  }

  p, ul {
    font-size:13pt;
    line-height:1.7em;
    letter-spacing: 0.02em;
  }

  @media (max-width: @screen-xs-max) {
    h1 {
      font-size:18pt;
    }

    h2 {
      font-size:16pt;
    }

    h3 {
      font-size:15pt;
    }
  }

  ul:not(.nav):not(.dropdown-menu) {
    padding:0;
    list-style: none;

    position:relative;

    li {
      padding-left:20px;

      &:before {
        position:absolute;
        left:4px;
        content: "+";
        color:@colorRed;
        font-size:14pt;
      }

      ul {
        //padding-left:14px;

        li {
          //padding-left:0px;

          &:before {
            content: "-";
          }
        }
      }
    }
  }

  #logo {
    text-align:center;
    padding:45px 0px;
  }

  .nav-text {
    text-transform:uppercase;
    font-weight: 200;
    font-size:18px;
    letter-spacing: 0.1em;
    margin-bottom:0px;
  }

  .nav-collapse-toggle {

    padding:15px 30px;
    cursor: pointer;

    .icon-div {
      max-height:20px;
    }

    span.glyphicon {
      color:@colorBlack;
      font-size: 18px;
      line-height:28px;
      //padding-top: 5px;
      height:20px;
    }

  }

  nav.navbar {
    border:0px !important;
    margin:0px 15px;
    margin-bottom:10px;

    @media (max-width: @screen-sm-max) {
      .navbar-collapse {

        .navbar-nav {
          margin:10px 0px;

          a {
            padding-left: 15px;
          }
        }
      }
    }

    ul.nav {
      li {
        a {
          .nav-text;

          .caret {
            display:none;
          }
        }
        //Dropdown
        ul.dropdown-menu {
          border-radius: 0px;
          box-shadow: 0;
          li {
            a {
              text-transform: none;
              //margin-left:12px;
              //font-size:15px;

              @media (max-width: @screen-sm-max) {
                &:before {
                  content: '-';
                  //color:@colorRed;
                }
              }
            }
          }
        }
      }
    }

    /**
    * Öffnet Dropdown beim Hover event statt klick
    */
    @media (min-width: @screen-md-min) {
      ul.nav li.dropdown:hover > ul.dropdown-menu {
        display: block;
      }
    }
  }

  #content {
    background-color:@colorWhite;
    padding:15px 30px;

    overflow:hidden;
    -ms-word-break:normal;
    word-break:normal;

    hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;

    .margin-0;
  }

  .team-title {
    background-color:white;
    margin-bottom:10px;
  }

  .team-member, .location {
    background-color:@colorWhite;
    margin-bottom:10px;
    height:250px;

    &.team-member {
      height:auto;
    }

    .team-content, .location-content {
      padding:15px 30px;
      position:relative;
      height:250px;

      &.team-content {
        height:auto;
        min-height:250px;
      }

      .team-member-title {
        p {
          margin:0;
        }
      }

      p {
        margin-top:8px;
        font-size:1.2em;
        line-height:1.6em;
      }

      div.infotext {
        display:none;

        &.open {
          display:block;
        }
      }

      .bottom-aligned-text.sticky {
        position:absolute;
        bottom:15px;

        // this makes sure that it
        // doesnt exceed its parents padding
        left:0;
        right:0;
        padding-left: inherit;
        padding-right: inherit;
      }

      @media (max-width: @screen-xs-max) {
        &.team-content {
          font-size:0.9em;

          h1 {
            //font-size:1.8em;
          }
        }
      }
    }

    .team-image, .location-map {
      padding-right:0;
      height:250px;

      background-size:cover;
      background-position: top center;
      background-repeat:no-repeat;

      .map {
        height:100%;
        width:100%;
      }
    }

    @media (max-width: 320px) {
    .team-image {
      display:none;
    }
    }
  }

  .downloads-row {
    margin:0;

    .col {
      padding:0px 10px 10px 0px;

      a {
        &:hover, &:focus {
          text-decoration: none;
        }

        .download-box {
          height:150px;
          padding:4px 6px;
          background-color:@colorRed;
          color:white;
          position:relative;

          .download-name {
            font-size:13.5pt;
            height:100px;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:normal;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }

          .download-description {
            display:none;
          }

          i.material-icons {
            position:absolute;

            &.download-type {
              bottom:6px;
              left:6px;
            }

            &.download-icon {
              bottom:6px;
              right:6px;
              color:white;
            }
          }
        }
      }
    }
  }



}